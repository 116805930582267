import React, { Component } from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from "gatsby";

class Projects extends Component {
    render() {
        return (
            <Layout>
                <SEO title="Projects"/>
                <h1>Projects</h1>
                <ul>
                    <li>
                        <a href="/">Portfolio Website: Belisle & Associates</a> &nbsp;
                        [<a target="_blank" href="https://gitlab.com/ed.belisle/portfolio-gatsby">source</a>]
                    </li>
                    <li >
                        <a href="https://medium.com/building-ibotta/understanding-pact-and-contract-testing-as-part-of-a-complete-testing-strategy-f062a52a317c?source=friends_link&sk=3a1769ff22eb4bd2e9a7e5dd34321fea"
                           target="_blank">
                            Pact and Contract Testing as Part of a Complete Testing Strategy
                        </a>
                    </li>
                </ul>
            </Layout>
        )
    }
}

export default Projects
